<template lang='pug'>
.w-100.bg-white(style='min-height: 100% !important')
  .bg-result.p-4
    .pf-display1 Voting results of Team Summaries
    .mt-4.radius-middle.w-100.bg-primary.df-row-ac.pr-2
      .group
      span.pl-3.pf-subhead.text-white Number of voters: {{ domain.userTotal || 0 }}
    .mt-3(v-for='(question, i) in domain.questions', :key='question.id')
      .pf-title Q{{ i + 1 }}
      .mt-1 {{ question.name }}
      div(v-if='question.no1Option != null && question.no1Option.length > 0')
        .mt-1.df-row-ac.flex-wrap
          span #Top1-
          .mx-1(v-for='(op, opIndex) in question.no1Option') {{ op.val }}
            span(v-if='opIndex < question.no1Option.length - 1') ,
        .mt-2
          van-progress(:percentage='parseInt(question.no1Option[0].percent || 0)', stroke-width='8', color='#fc4016') 
        .mt-1.df-row-ac-jb
          .df-row-ac
            .user
            .pf-18.text-primary.ml-2 {{ question.no1Option[0].total || 0 }}
          div {{ question.no1Option[0].percent || 0 }} %
      div(v-if='question.no2Option != null && question.no2Option.length > 0')
        .mt-1.df-row-ac.flex-wrap
          span #Top2-
          .mx-1(v-for='(op, opIndex) in question.no2Option') {{ op.val }}
            span(v-if='opIndex < question.no2Option.length - 1') ,
        .mt-2
          van-progress(:percentage='parseInt(question.no2Option[0].percent || 0)', stroke-width='8', color='#fc4016') 
        .mt-1.df-row-ac-jb
          .df-row-ac
            .user
            .pf-18.text-primary.ml-2 {{ question.no2Option[0].total || 0 }}
          div {{ question.no2Option[0].percent || 0 }} %
      div(v-if='question.no3Option != null && question.no3Option.length > 0')
        .mt-1.df-row-ac.flex-wrap
          span #Top3-
          .mx-1(v-for='(op, opIndex) in question.no3Option') {{ op.val }}
            span(v-if='opIndex < question.no3Option.length - 1') ,
        .mt-2
          van-progress(:percentage='parseInt(question.no3Option[0].percent || 0)', stroke-width='8', color='#fc4016') 
        .mt-1.df-row-ac-jb
          .df-row-ac
            .user
            .pf-18.text-primary.ml-2 {{ question.no3Option[0].total || 0 }}
          div {{ question.no3Option[0].percent || 0 }} %
</template>

<script>
import { Button, Progress } from 'vant'
import API from '@/api/api'

export default {
  components: {
    [Button.name]: Button,
    [Progress.name]: Progress
  },
  data() {
    return {
      show: false,
      domain: {}
    }
  },
  methods: {
    handleResult() {
      this.$router.push({
        path: '/success'
      })
    },
    loadData() {
      API.vote
        .list()
        .then((res) => {
          if (res.data.ok) {
            let data = res.data.data || {}
            this.domain = data
          }
        })
        .catch(() => {})
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss">
</style>
